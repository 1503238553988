<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування settings" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <!-- <va-input
                  label="robots.txt"
                  v-model="robots"
                  type="textarea"
                  :messages="['The recommended number of characters is 10000']"
                  :error="!!errors.seoconfig_robots"
                  :error-messages="errors.seoconfig_robots"
                  @input="delete errors.seoconfig_robots"
                /> -->
                <va-input
                  label="robots.txt"
                  v-model="robots_txt"
                  type="textarea"
                  :messages="['The recommended number of characters is 10000']"
                  :error="!!errors.robots_txt"
                  :error-messages="errors.robots_txt"
                  @input="delete errors.robots_txt"
                />
                <va-input
                  label="for_head_end"
                  v-model="for_head_end"
                  type="textarea"
                  :messages="['The recommended number of characters is 10000']"
                  :error="!!errors.v"
                  :error-messages="errors.for_head_end"
                  @input="delete errors.for_head_end"
                />
                <va-input
                  label="for_body_end"
                  v-model="for_body_end"
                  type="textarea"
                  :messages="['The recommended number of characters is 10000']"
                  :error="!!errors.for_body_end"
                  :error-messages="errors.for_body_end"
                  @input="delete errors.for_body_end"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
    //   robots: '',
      for_body_end: '',
      for_head_end: '',
      robots_txt: '',
      mainId: null,

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    // putData () {
    //   return {
    //     seoconfig_robots: this.robots,
    //   }
    // },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/setting/${this.mainId}`, {
        main: {
          for_body_end: this.for_body_end,
          for_head_end: this.for_head_end,
          id: this.mainId,
          robots_txt: this.robots_txt,
        },
      })
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
    //   axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/setting/`, {
    //       main: {

      //       }
      //   }, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      //     .then(response => {
      //       this.errors = response.data
      //       if (!Object.keys(this.errors).length) {
      //         this.submit()
      //       }
      //     })
      //     .catch(error => {
      //       console.log(error)
      //       this.showToast('Validate Fetch Error')
      //     })
      this.submit()
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/setting/1`)
        .then(response => {
          // console.log(response.data.main)
          this.mainId = response.data.main.id
          this.for_body_end = response.data.main.for_body_end
          this.for_head_end = response.data.main.for_head_end
          this.robots_txt = response.data.main.robots_txt
        //   this.robots = response.data.seoconfig_robots
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
